/**
 * This composable provides an easy way to check if a feature is enabled or not.
 * @param featureKey The key/name of the feature to check
 * @returns The value of the feature or false if the feature is not found
 */
export const useFeature = (featureKey: string): any => {
    const features = useRuntimeConfig().public.features as Record<string, string>;
    const feat = features[featureKey]
    // Check if the feature exists
    if (feat === undefined) {
        console.error(`Feature "${featureKey}" not found!\nPlease check if you have added it to the runtime config in your nuxt.config.ts`)
        return false
    }
    // Check if it has a value
    if (feat === '') {
        console.warn(`Feature "${featureKey}" has no value and is automatically being set to false!\nPlease check if you have set a value in your .env file or in the runtime config in your nuxt.config.ts`)
        return false
    }
    // Try to parse the feature as JSON (or boolean or number)
    try {
        return JSON.parse(feat);
    } catch {
        // continue
    }
    // Try to parse the feature as an array
    if (feat.startsWith('[') && feat.endsWith(']')) {
        return feat.slice(1, -1).split(',').map((item: string) => {
            const stringValue = item.trim().replaceAll('"', '').replaceAll("'", '')
            try {
                return JSON.parse(stringValue)
            } catch {
                return stringValue
            }
        });
    }
    // Return the feature as a string
    return feat;
}